import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../index.css";
import "./users.scss";
import Navbar from "../components/Navbar";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import {useNavigate} from "react-router-dom";

function Users() {
  const Token = localStorage.getItem("systemToken");
  const Navigate = useNavigate();
  useEffect(() => {
    if (!Token) {
      Navigate("/login");
    }
  }, []);
  const generatePassword = () => {
    var chars =
      "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var passwordLength = 12;
    var password = "";
    for (var i = 0; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    return password;
  };
  let emptyProduct = {
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    password: generatePassword(),
    designation:"",
    number:"",
  };

  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const [edit, setEdit] = useState(false);
  // eslint-disable-line react-hooks/exhaustive-deps

  const GetUsers = async () => {
    const res = await axios.get("https://server-wdkhklvgeq-uc.a.run.app/api" + "/allUsers");
    setProducts(res?.data?.data);
  };
  useEffect(() => {
    GetUsers();
  }, []);

  const Roles = [
    { label: "user", value: "user" },
    { label: "admin", value: "admin" },
  ];

  const openNew = () => {
    setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
    setEdit(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const saveProduct = async () => {
    setSubmitted(true);
    if (edit) {
      const prod=products.filter(x=>x.email===product.email);
      const pr=product;
      pr.id=prod?.[0]?._id;
      delete pr._id;
      delete pr.createdAt;
      delete pr.updatedAt;
      const res = await axios.put("https://server-wdkhklvgeq-uc.a.run.app/api" + "/editUser", {
        ...pr,
      });
      if (res.status === 201) {
        GetUsers();
        setProductDialog(false);
        setProduct(emptyProduct);
        setEdit(false);
      }
    } else {
      const user={
        firstName:product?.firstName,
        lastName:product?.lastName,
        email:product?.email,
        password:product?.password,
        role:product?.role
      }
      const signature={
        name:`${product?.firstName} ${product?.lastName}`,
        designation:product?.designation,
        number:product?.number
      }
      const res = await axios.post("https://server-wdkhklvgeq-uc.a.run.app/api" + "/adduser", {
        ...user,
      });
      const signatureRes=await axios.post("https://server-wdkhklvgeq-uc.a.run.app/api"+"/addSignature",{
        ...signature
      })
      if (res.status === 201 && signatureRes.status===201) {
        GetUsers();
        setProductDialog(false);
        setProduct(emptyProduct);
      }
    }
  };

  const editProduct = (product) => {
    setProduct({ ...product });
    setProductDialog(true);
    setEdit(true);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct =async () => {
    const data={
      id:product?._id
    }
    axios.post("https://server-wdkhklvgeq-uc.a.run.app/api"+"/deleteUser",{
      ...data
    }).then(res=>{
      if(res.status===201)
      {
        GetUsers();
        setDeleteProductDialog(false);
        setProduct(emptyProduct);
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "User Deleted",
          life: 3000,
        });
      }
    })
  };


  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));
    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };
    _product[`${name}`] = val;

    setProduct(_product);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="New"
          icon="pi pi-plus"
          className="p-button-success mr-2"
          onClick={openNew}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteProduct(rowData)}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="table-header">
      <h5 className="mx-0 my-1">Manage Users</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={saveProduct}
      />
    </React.Fragment>
  );
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  return (
    <>
      <Navbar />
      <div className="user_main">
        <div className="users_inner">
          <div className="datatable-crud-demo">
            <Toast ref={toast} />

            <div className="card">
              <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

              <DataTable
                ref={dt}
                value={products}
                dataKey="id"
                paginator
                rows={10}
                rowsPerPageOptions={[1,5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                globalFilter={globalFilter}
                header={header}
                responsiveLayout="scroll"
              >
                <Column
                  field="firstName"
                  header="First Name"
                  sortable
                  style={{ minWidth: "12rem" }}
                ></Column>
                <Column
                  field="lastName"
                  header="Last Name"
                  sortable
                  style={{ minWidth: "16rem" }}
                ></Column>
                <Column
                  field="email"
                  header="Email"
                  sortable
                  style={{ minWidth: "10rem" }}
                ></Column>
                <Column
                  field="role"
                  header="Role"
                  sortable
                  style={{ minWidth: "10rem" }}
                ></Column>
                <Column
                  body={actionBodyTemplate}
                  exportable={false}
                  style={{ minWidth: "8rem" }}
                ></Column>
              </DataTable>
            </div>

            <Dialog
              visible={productDialog}
              style={{ width: "450px" }}
              header="New user"
              modal
              className="p-fluid"
              footer={productDialogFooter}
              onHide={hideDialog}
            >
              <div className="field">
                <label htmlFor="firstName">First Name</label>
                <InputText
                  id="firstName"
                  value={product.firstName}
                  onChange={(e) => onInputChange(e, "firstName")}
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.firstName,
                  })}
                />
                {submitted && !product.firstName && (
                  <small className="p-error">First Name is required.</small>
                )}
              </div>
              <div className="field">
                <label htmlFor="lastName">Last Name</label>
                <InputText
                  id="lastName"
                  value={product.lastName}
                  onChange={(e) => onInputChange(e, "lastName")}
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.lastName,
                  })}
                />
                {submitted && !product.lastName && (
                  <small className="p-error">Last Name is required.</small>
                )}
              </div>
              <div className="field">
                <label htmlFor="email">email</label>
                <InputText
                  id="email"
                  value={product.email}
                  onChange={(e) => onInputChange(e, "email")}
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.email,
                  })}
                />
                {submitted && !product.email && (
                  <small className="p-error">email is required.</small>
                )}
              </div>
              <div className="field">
                <label htmlFor="password">password</label>
                <InputText
                  id="password"
                  value={product.password}
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.password,
                  })}
                />
                {submitted && !product.password && (
                  <small className="p-error">password is required.</small>
                )}
              </div>
              <div className="field">
                <label htmlFor="designation">designation</label>
                <InputText
                  id="designation"
                  value={product.designation}
                  required
                  autoFocus
                  onChange={(e) => onInputChange(e, "designation")}
                  className={classNames({
                    "p-invalid": submitted && !product.designation,
                  })}
                />
                {submitted && !product.designation && (
                  <small className="p-error">designation is required.</small>
                )}
              </div>
              <div className="field">
                <label htmlFor="number">number</label>
                <InputText
                  id="number"
                  value={product.number}
                  required
                  autoFocus
                  onChange={(e) => onInputChange(e, "number")}
                  className={classNames({
                    "p-invalid": submitted && !product.number,
                  })}
                />
                {submitted && !product.number && (
                  <small className="p-error">number is required.</small>
                )}
              </div>
              <div className="field">
                <label htmlFor="role">role</label>
                <Dropdown
                  value={product.role}
                  options={Roles}
                  onChange={(e) => onInputChange(e, "role")}
                  placeholder="Select a Role"
                />
                {submitted && !product.role && (
                  <small className="p-error">role is required.</small>
                )}
              </div>
            </Dialog>

            <Dialog
              visible={deleteProductDialog}
              style={{ width: "450px" }}
              header="Confirm"
              modal
              footer={deleteProductDialogFooter}
              onHide={hideDeleteProductDialog}
            >
              <div className="confirmation-content">
                <i
                  className="pi pi-exclamation-triangle mr-3"
                  style={{ fontSize: "2rem" }}
                />
                {product && (
                  <span>
                    Are you sure you want to delete <b>{product.name}</b>?
                  </span>
                )}
              </div>
            </Dialog>

            <Dialog
              visible={deleteProductsDialog}
              style={{ width: "450px" }}
              header="Confirm"
              modal
              footer={deleteProductsDialogFooter}
              onHide={hideDeleteProductsDialog}
            >
              <div className="confirmation-content">
                <i
                  className="pi pi-exclamation-triangle mr-3"
                  style={{ fontSize: "2rem" }}
                />
                {product && (
                  <span>
                    Are you sure you want to delete the selected products?
                  </span>
                )}
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    </>
  );
}

export default Users;
