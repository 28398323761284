import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Landing from "./pages/Landing";
import Users from "./pages/Users";
import Post from "./pages/Post";
import Signature from "./pages/Signature";
import Login from "./pages/Login";
import ChangePassword from "./pages/ChangePassword";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Landing />}></Route>
        <Route path="/users" element={<Users/>}></Route>
        <Route path="/posts" element={<Post/>}></Route>
        <Route path="/signature" element={<Signature/>}></Route>
        <Route path="/login" element={<Login/>}></Route>
        <Route path="/changePassword" element={<ChangePassword/>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
