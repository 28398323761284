import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import "./login.scss";
import Navbar from "../components/Navbar";
import axios from "axios";
import { Toast } from "primereact/toast";
import {useNavigate} from "react-router-dom";
import { Divider } from 'primereact/divider';

function ChangePassword() {
    const users=localStorage.getItem("systemUser");
    let user;
    if(users)
    {
      user=JSON.parse(users);
    }
    const Navigate=useNavigate();
    const [showMessage, setShowMessage] = useState(false);
    const toast = useRef(null);
    const formik = useFormik({
      initialValues: {
        email: user?.email,
        password: "",
        newPassword:""
      },
      validate: (data) => {
        let errors = {};
        if (!data.email) {
          errors.email = "Email is required.";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
        ) {
          errors.email = "Invalid email address. E.g. example@email.com";
        }

        if (!data.password) {
          errors.password = "Password is required.";
        }
        if (!data.newPassword) {
          errors.password = "new Password is required.";
        }
        return errors;
      },
      onSubmit: (data) => {
        axios.put("https://server-wdkhklvgeq-uc.a.run.app/api"+"/changePassword",{
          ...data
        }).then(res=>{
          if(res.status===201)
          {
            setShowMessage(true);
            formik.resetForm();
            Navigate("/")
          }
        }).catch(err=>{
          toast.current.show({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
        })
      },
    });

    const isFormFieldValid = (name) =>
      !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
      return (
        isFormFieldValid(name) && (
          <small className="p-error">{formik.errors[name]}</small>
        )
      );
    };

    const dialogFooter = (
      <div className="flex justify-content-center">
        <Button
          label="OK"
          className="p-button-text"
          autoFocus
          onClick={() => setShowMessage(false)}
        />
      </div>
  );
  const passwordHeader = <h6>Pick a password</h6>;
  const passwordFooter = (
      <React.Fragment>
          <Divider />
          <p className="mt-2">Suggestions</p>
          <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
              <li>At least one lowercase</li>
              <li>At least one uppercase</li>
              <li>At least one numeric</li>
              <li>Minimum 8 characters</li>
          </ul>
      </React.Fragment>
  );
  return (
    <>
    <Toast ref={toast} />
     <Navbar/>
      <div className="login_main">
      <div className="form-demo">
        <Dialog
          visible={showMessage}
          onHide={() => setShowMessage(false)}
          position="top"
          footer={dialogFooter}
          showHeader={false}
          breakpoints={{ "960px": "80vw" }}
          style={{ width: "30vw" }}
        >
          <div className="flex align-items-center flex-column pt-6 px-3">
            <i
              className="pi pi-check-circle"
              style={{ fontSize: "5rem", color: "var(--green-500)" }}
            ></i>
            <h5>Password changed!</h5>
          </div>
        </Dialog>

        <div className="flex justify-content-center">
          <div className="card">
            <h5 className="text-center">Change Password</h5>
            <form onSubmit={formik.handleSubmit} className="p-fluid">
              <div className="field">
                <span className="p-float-label p-input-icon-right">
                  <i className="pi pi-envelope" />
                  <InputText
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    className={classNames({
                      "p-invalid": isFormFieldValid("email"),
                    })}
                  />
                  <label
                    htmlFor="email"
                    className={classNames({
                      "p-error": isFormFieldValid("email"),
                    })}
                  >
                    Email*
                  </label>
                </span>
                {getFormErrorMessage("email")}
              </div>
              <div className="field">
                <span className="p-float-label">
                  <Password
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    toggleMask
                    className={classNames({
                      "p-invalid": isFormFieldValid("password"),
                    })}
                  />
                  <label
                    htmlFor="password"
                    className={classNames({
                      "p-error": isFormFieldValid("password"),
                    })}
                  >
                    Password*
                  </label>
                </span>
                {getFormErrorMessage("password")}
              </div>
              <div className="field">
                <span className="p-float-label">
                  <Password
                    id="newPassword"
                    name="newPassword"
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    toggleMask
                    className={classNames({
                      "p-invalid": isFormFieldValid("newPassword"),
                    })}
                    header={passwordHeader}
                    footer={passwordFooter}
                  />
                  <label
                    htmlFor="newPassword"
                    className={classNames({
                      "p-error": isFormFieldValid("newPassword"),
                    })}
                  >
                   New Password*
                  </label>
                </span>
                {getFormErrorMessage("newPassword")}
              </div>
              <Button type="submit" label="Submit" className="mt-2" />
            </form>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default ChangePassword