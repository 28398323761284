import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../index.css";
import "./users.scss";
import Navbar from "../components/Navbar";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import moment from "moment";
import { RadioButton } from 'primereact/radiobutton';
import { ToggleButton } from 'primereact/togglebutton';
import { Editor } from 'primereact/editor';
import {useNavigate} from "react-router-dom"


function Post() {
  const Token = localStorage.getItem("systemToken");
  const Navigate = useNavigate();
  useEffect(() => {
    if (!Token) {
      Navigate("/login");
    }
  }, []);
  let emptyProduct = {
    title: "",
    description: "",
    users: "",
    important: false,
  };
  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const [edit, setEdit] = useState(false);
  const [description,setDescription]=useState("");
  // eslint-disable-line react-hooks/exhaustive-deps

  const GetUsers = async () => {
    const res = await axios.get("https://server-wdkhklvgeq-uc.a.run.app/api" + "/allposts");
    const sorted=res?.data?.data?.sort((a,b)=>b.updatedAt-a.updatedAt);
    setProducts(sorted);
  };
  useEffect(() => {
    GetUsers();
  }, []);

  const Roles = [
    { label: "user", value: "user" },
    { label: "admin", value: "admin" },
  ];

  const openNew = () => {
    setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
    setEdit(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const saveProduct = async () => {
    setSubmitted(true);
    if (edit) {
      const prod = products.filter((x) => x.title === product.title);
      const pr = product;
      pr.id = pr?._id;
      pr.description=description;
      delete pr._id;
      delete pr.createdAt;
      delete pr.updatedAt;
      const res = await axios.put("https://server-wdkhklvgeq-uc.a.run.app/api" + "/EditPost", {
        ...pr,
      });
      if (res.status === 201) {
        GetUsers();
        setProductDialog(false);
        setProduct(emptyProduct);
        setEdit(false);
      }
    } else {
      const post={
        title:product?.title,
        description:description,
        users:product?.users,
        important:product?.important
      }
      const res = await axios.post("https://server-wdkhklvgeq-uc.a.run.app/api" + "/addPost", {
        ...post,
      });
      if (res.status === 201) {
        GetUsers();
        setProductDialog(false);
        setProduct(emptyProduct);
        setDescription("")
      }
    }
  };

  const editProduct = (product) => {
    setProduct({ ...product });
    setProductDialog(true);
    setEdit(true);
  };

  // console.log("product", product);

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = async () => {
    const data = {
      id: product?._id,
    };
    axios
      .post("https://server-wdkhklvgeq-uc.a.run.app/api" + "/deletePost", {
        ...data,
      })
      .then((res) => {
        if (res.status === 201) {
          GetUsers();
          setDeleteProductDialog(false);
          setProduct(emptyProduct);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Post Deleted",
            life: 3000,
          });
        }
      });
  };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));
    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };
    _product[`${name}`] = val;

    setProduct(_product);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="New"
          icon="pi pi-plus"
          className="p-button-success mr-2"
          onClick={openNew}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteProduct(rowData)}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className="table-header">
      <h5 className="mx-0 my-1">Manage Posts</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={saveProduct}
      />
    </React.Fragment>
  );
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );
  const onCategoryChange = (e) => {
    let _product = {...product};
   _product['important'] = e;
    setProduct(_product);
   }

  const onDescriptionChange = (e) => {
    console.log("e",e)
    setDescription(e)
   }

  const Getdate = (rowData) => {
    return moment(rowData?.createdAt).format("DD / MMM / YYYY");
  };
  return (
    <>
      <Navbar />
      <div className="user_main">
        <div className="users_inner">
          <div className="datatable-crud-demo">
            <Toast ref={toast} />

            <div className="card">
              <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

              <DataTable
                ref={dt}
                value={products}
                dataKey="id"
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} posts"
                globalFilter={globalFilter}
                header={header}
                responsiveLayout="scroll"
              >

                <Column
                  field="title"
                  header="Title"
                  sortable
                  style={{ minWidth: "12rem" }}
                ></Column>
                <Column
                  field="users"
                  header="Visible To"
                  sortable
                  style={{ minWidth: "16rem" }}
                ></Column>
                <Column
                  field="important"
                  header="Important"
                  sortable
                  style={{ minWidth: "10rem" }}
                ></Column>
                <Column
                  field="createdAt"
                  header="Date of creation"
                  sortable
                  style={{ minWidth: "10rem" }}
                  body={Getdate}
                ></Column>
                <Column
                  body={actionBodyTemplate}
                  exportable={false}
                  style={{ minWidth: "8rem" }}
                ></Column>
              </DataTable>
            </div>

            <Dialog
              visible={productDialog}
              style={{ width: "600px" }}
              header="New post"
              modal
              className="p-fluid"
              footer={productDialogFooter}
              onHide={hideDialog}
            >
              <div className="field">
                <label htmlFor="title">Title</label>
                <InputText
                  id="title"
                  value={product.title}
                  onChange={(e) => onInputChange(e, "title")}
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.title,
                  })}
                />
                {submitted && !product.title && (
                  <small className="p-error">Title is required.</small>
                )}
              </div>
              <div className="field">
                <label htmlFor="description">Description</label>
                <Editor style={{height:'320px'}} value={product.description} onTextChange={(e) => onDescriptionChange(e.htmlValue)} />
              </div>
              <div className="field">
                <label htmlFor="users">
                  Visible To (if user selected then visible to both users and
                  admins)
                </label>
                <Dropdown
                  value={product.users}
                  options={Roles}
                  onChange={(e) => onInputChange(e, "users")}
                  placeholder="Select a visibility status"
                />
                {submitted && !product.users && (
                  <small className="p-error">visibility is required.</small>
                )}
              </div>
              <div className="field">
                <label htmlFor="important">
                  Important
                </label>
                <ToggleButton onLabel="Yes" offLabel="No" onIcon="pi pi-check" offIcon="pi pi-times" checked={product?.important} onChange={(e) => onCategoryChange(e.value)} />
              </div>
            </Dialog>

            <Dialog
              visible={deleteProductDialog}
              style={{ width: "450px" }}
              header="Confirm"
              modal
              footer={deleteProductDialogFooter}
              onHide={hideDeleteProductDialog}
            >
              <div className="confirmation-content">
                <i
                  className="pi pi-exclamation-triangle mr-3"
                  style={{ fontSize: "2rem" }}
                />
                {product && (
                  <span>
                    Are you sure you want to delete <b>{product.name}</b>?
                  </span>
                )}
              </div>
            </Dialog>

            <Dialog
              visible={deleteProductsDialog}
              style={{ width: "450px" }}
              header="Confirm"
              modal
              footer={deleteProductsDialogFooter}
              onHide={hideDeleteProductsDialog}
            >
              <div className="confirmation-content">
                <i
                  className="pi pi-exclamation-triangle mr-3"
                  style={{ fontSize: "2rem" }}
                />
                {product && (
                  <span>
                    Are you sure you want to delete the selected products?
                  </span>
                )}
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    </>
  );
}

export default Post;
