import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "../index.css";
import "./users.scss";
import Navbar from "../components/Navbar";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import useDynamicRefs from "use-dynamic-refs";
import { useNavigate } from "react-router-dom";

function Signature() {
  const Token = localStorage.getItem("systemToken");
  const Navigate = useNavigate();
  useEffect(() => {
    if (!Token) {
      Navigate("/login");
    }
  }, []);
  let emptyProduct = {
    name: "",
    designation: "",
    number: "",
  };

  const [products, setProducts] = useState(null);
  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);
  const [edit, setEdit] = useState(false);
  const [getRef, setRef] = useDynamicRefs();
  // eslint-disable-line react-hooks/exhaustive-deps

  const GetUsers = async () => {
    const res = await axios.get(
      "https://server-wdkhklvgeq-uc.a.run.app/api" + "/allSignature"
    );
    setProducts(res?.data?.data);
  };
  useEffect(() => {
    GetUsers();
  }, []);

  const Roles = [
    { label: "user", value: "user" },
    { label: "admin", value: "admin" },
  ];

  const openNew = () => {
    setProduct(emptyProduct);
    setSubmitted(false);
    setProductDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setProductDialog(false);
    setEdit(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  const saveProduct = async () => {
    setSubmitted(true);
    if (edit) {
      const prod = products.filter((x) => x.name === product.name);
      const pr = product;
      pr.id = pr?._id;
      delete pr._id;
      delete pr.createdAt;
      delete pr.updatedAt;
      const res = await axios.put(
        "https://server-wdkhklvgeq-uc.a.run.app/api" + "/editSignature",
        {
          ...pr,
        }
      );
      if (res.status === 201) {
        GetUsers();
        setProductDialog(false);
        setProduct(emptyProduct);
        setEdit(false);
      }
    } else {
      const res = await axios.post(
        "https://server-wdkhklvgeq-uc.a.run.app/api" + "/addSignature",
        {
          ...product,
        }
      );
      if (res.status === 201) {
        GetUsers();
        setProductDialog(false);
        setProduct(emptyProduct);
      }
    }
  };

  const editProduct = (product) => {
    setProduct({ ...product });
    setProductDialog(true);
    setEdit(true);
  };

  const confirmDeleteProduct = (product) => {
    setProduct(product);
    setDeleteProductDialog(true);
  };

  const deleteProduct = async () => {
    const data = {
      id: product?._id,
    };
    axios
      .post("https://server-wdkhklvgeq-uc.a.run.app/api" + "/deleteSignature", {
        ...data,
      })
      .then((res) => {
        if (res.status === 201) {
          GetUsers();
          setDeleteProductDialog(false);
          setProduct(emptyProduct);
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Signature Deleted",
            life: 3000,
          });
        }
      });
  };

  const deleteSelectedProducts = () => {
    let _products = products.filter((val) => !selectedProducts.includes(val));
    setProducts(_products);
    setDeleteProductsDialog(false);
    setSelectedProducts(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _product = { ...product };
    _product[`${name}`] = val;

    setProduct(_product);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="New"
          icon="pi pi-plus"
          className="p-button-success mr-2"
          onClick={openNew}
        />
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteProduct(rowData)}
        />
        <Button
          icon="pi-file-o"
          className="p-button-rounded ml-2"
          label="copy"
          onClick={() => copyToClipboard(rowData)}
        ></Button>
      </React.Fragment>
    );
  };

  const header = (
    <div className="table-header">
      <h5 className="mx-0 my-1">Manage Signatures</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Search..."
        />
      </span>
    </div>
  );

  const productDialogFooter = (
    <React.Fragment>
      <Button
        label="Cancel"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Save"
        icon="pi pi-check"
        className="p-button-text"
        onClick={saveProduct}
      />
    </React.Fragment>
  );
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteProduct}
      />
    </React.Fragment>
  );
  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={deleteSelectedProducts}
      />
    </React.Fragment>
  );

  const getSignature = (rowData) => {
    return (
      <div className="signature" ref={setRef(rowData?.name)}>
        <a href="https://systemagency.com/home">
          <img
            src="https://res.cloudinary.com/dwxqg9so3/image/upload/v1708648641/logo_ervico.png"
            className="image_main"
            style={{objectFit:"contain"}}
          ></img>
        </a>
        <div className="this_is">
          <span className="name_span">{rowData?.name}</span>
          <span className="name_span">{rowData?.designation}</span>
          <span className="other_span">{rowData?.number}</span>
          <a href="https://systemagency.com/home" className="some_span">
            SYSTEMAGENCY.COM
          </a>
        </div>
      </div>
    );
  };

  const copyToClipboard = (rowData) => {
    let copy = getRef(rowData?.name);
    let copyText = copy.current;
    const range = document.createRange();
    if (copyText) {
      range.selectNode(copyText);
    }
    const windowSelection = window.getSelection();
    if (windowSelection) {
      windowSelection.removeAllRanges();
      windowSelection.addRange(range);
    }
    try {
      let successful = document.execCommand("copy");
      if (successful) {
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "signature copied",
          life: 3000,
        });
      }
    } catch (err) {
      console.log("Fail");
    }
  };
  return (
    <>
      <Navbar />
      <div className="user_main">
        <div className="users_inner">
          <div className="datatable-crud-demo">
            <Toast ref={toast} />
            <div className="card">
              <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
              <DataTable
                ref={dt}
                value={products}
                dataKey="id"
                paginator
                rows={10}
                rowsPerPageOptions={[1, 5, 10, 25]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} signatures"
                globalFilter={globalFilter}
                header={header}
                responsiveLayout="scroll"
              >
                <Column
                  field="name"
                  header="Name"
                  sortable
                  style={{ minWidth: "12rem" }}
                ></Column>
                <Column
                  field="designation"
                  header="Designation"
                  sortable
                  style={{ minWidth: "16rem" }}
                ></Column>
                {window?.innerWidth > 768 && (
                  <Column
                    field="signature"
                    header="Signature"
                    sortable
                    style={{ minWidth: "10rem" }}
                    body={getSignature}
                  ></Column>
                )}
                <Column
                  field="number"
                  header="Number"
                  sortable
                  style={{ minWidth: "10rem" }}
                ></Column>
                <Column
                  body={actionBodyTemplate}
                  exportable={false}
                  style={{ minWidth: "8rem" }}
                ></Column>
              </DataTable>
            </div>

            <Dialog
              visible={productDialog}
              style={{ width: "450px" }}
              header="New signature"
              modal
              className="p-fluid"
              footer={productDialogFooter}
              onHide={hideDialog}
            >
              <div className="field">
                <label htmlFor="name">Name</label>
                <InputText
                  id="name"
                  value={product.name}
                  onChange={(e) => onInputChange(e, "name")}
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.name,
                  })}
                />
                {submitted && !product.name && (
                  <small className="p-error">Name is required.</small>
                )}
              </div>
              <div className="field">
                <label htmlFor="designation">Designation</label>
                <InputText
                  id="designation"
                  value={product.designation}
                  onChange={(e) => onInputChange(e, "designation")}
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.designation,
                  })}
                />
                {submitted && !product.designation && (
                  <small className="p-error">Designation is required.</small>
                )}
              </div>
              <div className="field">
                <label htmlFor="number">Number</label>
                <InputText
                  id="number"
                  value={product.number}
                  onChange={(e) => onInputChange(e, "number")}
                  required
                  autoFocus
                  className={classNames({
                    "p-invalid": submitted && !product.number,
                  })}
                />
                {submitted && !product.number && (
                  <small className="p-error">number is required.</small>
                )}
              </div>
            </Dialog>

            <Dialog
              visible={deleteProductDialog}
              style={{ width: "450px" }}
              header="Confirm"
              modal
              footer={deleteProductDialogFooter}
              onHide={hideDeleteProductDialog}
            >
              <div className="confirmation-content">
                <i
                  className="pi pi-exclamation-triangle mr-3"
                  style={{ fontSize: "2rem" }}
                />
                {product && (
                  <span>
                    Are you sure you want to delete <b>{product.name}</b>?
                  </span>
                )}
              </div>
            </Dialog>

            <Dialog
              visible={deleteProductsDialog}
              style={{ width: "450px" }}
              header="Confirm"
              modal
              footer={deleteProductsDialogFooter}
              onHide={hideDeleteProductsDialog}
            >
              <div className="confirmation-content">
                <i
                  className="pi pi-exclamation-triangle mr-3"
                  style={{ fontSize: "2rem" }}
                />
                {product && (
                  <span>
                    Are you sure you want to delete the selected products?
                  </span>
                )}
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signature;
