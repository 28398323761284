import axios from "axios";
import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import "./landing.scss";
import { useNavigate } from "react-router-dom";

function Landing() {
  const Token = localStorage.getItem("systemToken");
  const Navigate = useNavigate();
  useEffect(() => {
    if (!Token) {
      Navigate("/login");
    }
  }, []);
  const [products, setProducts] = useState(null);
  const getPosts = async () => {
    const res = await axios.get("https://server-wdkhklvgeq-uc.a.run.app/api" + "/allposts");
    setProducts(res?.data?.data);
  };
  useEffect(() => {
    getPosts();
  }, []);

  const users=localStorage.getItem("systemUser");
  let user;
  if(users)
  {
    user=JSON.parse(users);
  }

  return (
    <>
      <Navbar />
      <div className="landing_main">
        <div className="landing__inner">
             <div className="left_column">
             {products?.map((prod, index) => {
            if(user.role==="user")
            {
              if(prod.users==="user" && prod?.important===false)
              {
                return (
                  <div className="grid_item">
                    <div className="divi">
                    <p>{prod?.title}</p>
                    {prod?.important && <div>
                    <span>Important</span>
                    </div>}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{ __html: prod?.description }}
                    ></div>
                  </div>
                );
              }
            }
            else{
              if(prod?.important===false)
              {
                return (
                  <div className="grid_item">
                    <div className="divi">
                    <p>{prod?.title}</p>
                    {prod?.important && <div>
                    <span>Important</span>
                    </div>}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{ __html: prod?.description }}
                    ></div>
                  </div>
                );
              }
            }
          })}
             </div>
             <div className="right_column">
             {products?.map((prod, index) => {
            if(user.role==="user")
            {
              if(prod.users==="user" && prod?.important===true)
              {
                return (
                  <div className="grid_item">
                    <div className="divi">
                    <p>{prod?.title}</p>
                    {prod?.important && <div>
                    <span>Important</span>
                    </div>}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{ __html: prod?.description }}
                    ></div>
                  </div>
                );
              }
            }
            else{
             if(prod?.important===true)
             {
              return (
                <div className="grid_item">
                  <div className="divi">
                  <p>{prod?.title}</p>
                  {prod?.important && <div>
                  <span>Important</span>
                  </div>}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: prod?.description }}
                  ></div>
                </div>
              );
             }
            }
          })}
             </div>
        </div>
      </div>
    </>
  );
}

export default Landing;
