import React, { useState } from "react";
import "./navbar.scss";
import { MdArrowDropDown } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";


function Navbar() {
  const [open, setOpen] = useState(false);
  const navigate=useNavigate()
  const Token=localStorage.getItem("systemToken");
  const users=localStorage.getItem("systemUser");
  let user;
  if(users)
  {
    user=JSON.parse(users);
  }

  const handleLogout=()=>{
     setOpen(!open);
     localStorage.removeItem("systemToken");
     localStorage.removeItem("systemUser");
     navigate("/login")
  }

  return (
    <div className="NavbarMain">
      <div className="navbar__Left">
        <img
          src="https://res.cloudinary.com/dwxqg9so3/image/upload/v1708648641/logo_ervico.png"
          alt="logo"
          onClick={()=>navigate("/")}
          style={{cursor:"pointer"}}
        ></img>
      </div>
      {Token && <div className="navbar_right">
        <p>Welcome {user?.firstName}</p>
        <i onClick={() => setOpen(!open)}>
          <MdArrowDropDown />
        </i>
        {open && (
          <div className="drop-down">
            <NavLink to="/changePassword" className="linkss" onClick={() => setOpen(!open)}>
              profile
            </NavLink>
            {user?.role==="admin" && <>
            <NavLink to="/users" className="linkss" onClick={() => setOpen(!open)}>
              users
            </NavLink>
            <NavLink to="/posts" className="linkss" onClick={() => setOpen(!open)}>
              posts
            </NavLink>
            <NavLink to="/signature" className="linkss" onClick={() => setOpen(!open)}>
              signatures
            </NavLink></>}
            <p onClick={handleLogout}>logout</p>
          </div>
        )}
      </div>}
    </div>
  );
}

export default Navbar;
